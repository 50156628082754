export function getShopPayText(terms, item) {
  // You must reside within the United States (U.S.) and use a payment card with a United States billing address. All transactions must be carried out in USD.
  // https://help.shop.app/hc/en-us/articles/4408790562836-Shop-Pay-Installments-eligibility-and-requirements#:~:text=1.%20Eligibility%20criteria

  if (window.Shopify.currency?.active !== 'USD' || window.Shopify.country !== 'US') {
    return null;
  }
  const priceFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

  const toNumber = (price) => Number(price.replace(',', '').replace('$', ''));

  if (item.price < toNumber(terms.min_price)) {
    return `Pay over time for orders over <b>${terms.min_price}</b> with`;
  }

  if (item.price > toNumber(terms.max_price)) {
    return `Split your purchase into installments for orders up to <b>${terms.max_price}</b> with`;
  }

  const eligiblePlans = terms.financing_plans.filter(
    (plan) => item.price >= toNumber(plan.min_price) && item.price <= toNumber(plan.max_price),
  );

  const splitPayTerm = eligiblePlans
    .find((plan) => plan.terms.some((term) => term.loan_type === 'split_pay'))
    ?.terms.find((term) => term.loan_type === 'split_pay');

  const interestTerms = eligiblePlans
    .find((plan) => plan.terms.some((term) => term.loan_type === 'interest'))
    ?.terms.filter((term) => term.loan_type === 'interest');

  const longestInterestTerm = interestTerms?.length
    ? interestTerms.reduce(
        (max, term) => (term.installments_count > max.installments_count ? term : max),
        interestTerms[0],
      )
    : null;

  let monthlyPaymentFormat;
  if (longestInterestTerm) {
    const interestRate = longestInterestTerm.apr / 100;
    const paymentsCount = longestInterestTerm.installments_count;

    const monthlyPayment =
      (item.price * (interestRate / 12)) / (1 - (1 + interestRate / 12) ** -paymentsCount);
    monthlyPaymentFormat = priceFormatter.format(monthlyPayment);
  }

  if (splitPayTerm && longestInterestTerm) {
    return `${splitPayTerm.installments_count} interest-free installments, or from <b>${monthlyPaymentFormat}</b>/mo with`;
  } else if (longestInterestTerm) {
    return `From <b>${monthlyPaymentFormat}</b>/mo with`;
  } else if (splitPayTerm) {
    monthlyPaymentFormat = priceFormatter.format(item.price / splitPayTerm.installments_count);
    return `Pay in ${splitPayTerm.installments_count} interest-free installments of <b>${monthlyPaymentFormat}</b> with`;
  }
}
