import shopifyAthensGenericDefaults from '../shopify-athens-generic/config.js';
import { getShopPayText } from '../../Client/utils/shopifyPay.js';

const wheelsFields = shopifyAthensGenericDefaults.Widgets.find((w) => w.name === 'FacetBarWheels').fields;
const tiresFields = shopifyAthensGenericDefaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;

const wheelsCollectionUrl = '/collections/wheels';
const tiresCollectionUrl = '/collections/tires-all-2023';
const isOnHomePage = window.location.pathname === '/';

const isAllProductsWheels = () => {
  return window.Convermax.getSearchResponse()
    ?.facets.get('FitmentType')
    ?.values.find((v) => v.value === 'Wheel');
};
const isAllProductsTires = () => {
  return window.Convermax.getSearchResponse()
    ?.facets.get('FitmentType')
    ?.values.find((v) => v.value === 'Tire');
};

export default {
  includes: ['shopify-athens-generic'],
  ...shopifyAthensGenericDefaults,
  Widgets: [
    ...shopifyAthensGenericDefaults.Widgets,
    {
      name: 'HeaderVehicleWidget_tab',
      type: 'VehicleWidget',
      location: '#cm-home-ymm',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 770,
    },
    {
      name: 'WheelPanel_tab',
      type: 'RequestPanel',
      location: '#cm-home-wheel',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      isAutoRedirectDisabled: !isOnHomePage,
      redirectUrl: wheelsCollectionUrl,
      selectFields: wheelsFields,
      shouldBeIsolated: () => !isAllProductsWheels(),
    },
    {
      name: 'TirePanel_tab',
      type: 'RequestPanel',
      location: '#cm-home-tire',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      isAutoRedirectDisabled: !isOnHomePage,
      redirectUrl: tiresCollectionUrl,
      selectFields: tiresFields,
      shouldBeIsolated: () => !isAllProductsTires(),
    },
  ],
};

/* eslint-disable camelcase */
const shopPayTerms = {
  min_price: '$35.00',
  max_price: '$30,000.00',
  financing_plans: [
    {
      min_price: '$35.00',
      max_price: '$49.99',
      terms: [{ apr: 0, loan_type: 'split_pay', installments_count: 2 }],
    },
    {
      min_price: '$50.00',
      max_price: '$149.99',
      terms: [{ apr: 0, loan_type: 'split_pay', installments_count: 4 }],
    },
    {
      min_price: '$150.00',
      max_price: '$999.99',
      terms: [
        { apr: 0, loan_type: 'split_pay', installments_count: 4 },
        { apr: 15, loan_type: 'interest', installments_count: 3 },
        { apr: 15, loan_type: 'interest', installments_count: 6 },
        { apr: 15, loan_type: 'interest', installments_count: 12 },
      ],
    },
    {
      min_price: '$1,000.00',
      max_price: '$30,000.00',
      terms: [
        { apr: 15, loan_type: 'interest', installments_count: 3 },
        { apr: 15, loan_type: 'interest', installments_count: 6 },
        { apr: 15, loan_type: 'interest', installments_count: 12 },
      ],
    },
  ],
  installments_buyer_prequalification_enabled: true,
  seller_id: 1365499,
};
/* eslint-enable camelcase */
window.Convermax.getShopPayText = (item) => getShopPayText(shopPayTerms, item);
